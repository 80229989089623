<template>
  <div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Postulante: {{name}}</p>
        <button class="delete" aria-label="close" @click="close"></button>
      </header>
      <section class="modal-card-body">
        <div class="columns is-multiline is-centered">
          <div class="column is-12">
           <label>Seleccione el nuevo estatus del postulante</label>
          </div>
          <div class="column is-6 has-text-centered">
           <div class="select is-fullwidth">
              <select
                v-validate="'required'"
                data-vv-as="estatus"
                name="status"
                v-model="status"
              >
                <option :value="null">Seleccione una opción</option>
                <option value="2">Aceptado</option>
                <option value="3">Rechazado</option>
              </select>
            </div>
            <span class="is-error-input">{{ vverrors.first('status') }}</span>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-small" :disabled="loading" @click="close">Cerrar</button>
        <button
          class="button is-active-b is-small"
          @click="updateStatus"
          :disabled="loading"
          :class="{'is-loading': loading}"
        >
          Guardar
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
import api from '@/libs/api'
export default {
  name: 'UpdateStatusPostulante',
  props: {
    name: {
      type: String,
      default: ''
    },
    close: {
      type: Function,
      default: () => {}
    },
    getPostulante: {
      type: Function,
      default: () => {}
    },
    postulanteId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      loading: false,
      status: null
    }
  },
  methods: {
    async updateStatus () {
      if (await this.$validator.validateAll()) {
        this.loading = true
        console.log(this.postulanteId, this.status)
        const response = await api.updateStatusPostulante(this.postulanteId, this.status)
        if (response.success) {
          this.getPostulante(this.postulanteId)
          this.close()
        }
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.divider {
  margin: 0.5rem 0;
}
.field {
  .label {
    font-size: 13px;
    text-align: left;
    margin-left: 0.2rem;
    font-weight: 500;
  }
}
</style>
